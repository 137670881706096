import unhead_KgADcZ0jPj from "/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import navigation_repaint_client_bs2bWDGRIE from "/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_A0OWXRrUgq from "/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_tMGwffvjBc from "/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/node_modules/@formkit/auto-animate/nuxt/runtime/plugin.mjs";
import plugin_8SbxDRbG6Y from "/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/node_modules/dayjs-nuxt/dist/runtime/plugin.mjs";
import i18n_yfWm7jX06p from "/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_client_LcKgStRyi6 from "/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import pwa_icons_Y6agwlnXZq from "/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa-icons.mjs";
import pwa_client_Eorgdr12jA from "/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import directives_plugin_cAhrVQth0F from "/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/plugins/directives.plugin.ts";
import keycloak_plugin_eq1o3HRoTE from "/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/plugins/keycloak.plugin.ts";
import recaptcha_plugin_wF9QjWzvqs from "/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/plugins/recaptcha.plugin.ts";
import sentry_plugin_nSQf93Ie8z from "/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/plugins/sentry.plugin.ts";
export default [
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_tMGwffvjBc,
  plugin_8SbxDRbG6Y,
  i18n_yfWm7jX06p,
  plugin_client_LcKgStRyi6,
  pwa_icons_Y6agwlnXZq,
  pwa_client_Eorgdr12jA,
  directives_plugin_cAhrVQth0F,
  keycloak_plugin_eq1o3HRoTE,
  recaptcha_plugin_wF9QjWzvqs,
  sentry_plugin_nSQf93Ie8z
]